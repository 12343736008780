import React from "react";
import { MotionBox, MotionImage } from "../components/MotionComponent";
import { Box, Typography } from "@mui/material";
import EnglishTeeth from "../imgs/englishTeeth.png";
import ArabicTeeth from "../imgs/arabicTeeth.png";
import { AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const pageVariants = {
  initial: {
    scaleY: 0,
    originY: 0,
  },
  animate: {
    scaleY: 1,
    originY: 0,
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
  exit: {
    scaleY: 0,
    originY: 0,
    transition: {
      duration: 0.5,
      ease: "easeInOut",
    },
  },
};

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const naviagte = useNavigate();

  return (
    <MotionBox
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      sx={{
        backgroundImage: (theme) =>
          `linear-gradient(150deg, ${theme.palette.language.light}, ${theme.palette.language.dark})`,
      }}
    >
      <MotionBox
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          minHeight: "100vh",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "50%" },
            textAlign: "center",
          }}
        >
          <MotionImage
            whileHover={{
              y: -20,
            }}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { delay: 1.2, duration: 0.5 },
            }}
            src={EnglishTeeth}
            sx={{ maxWidth: "100%", cursor: "pointer" }}
            onClick={async () => {
              document.dir = "ltr";
              await i18n.changeLanguage("en");
              naviagte("/home");
            }}
          />
        </Box>
        <Box
          sx={{
            width: { xs: "100%", sm: "50%" },
          }}
        >
          <MotionImage
            whileHover={{
              y: -20,
            }}
            src={ArabicTeeth}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { delay: 1.2, duration: 0.5 },
            }}
            sx={{ maxWidth: "100%", cursor: "pointer" }}
            onClick={async () => {
              document.dir = "rtl";
              await i18n.changeLanguage("ar");
              naviagte("/home");
            }}
          />
        </Box>
      </MotionBox>
    </MotionBox>
  );
};

export default LanguageSelector;
