import { Box, styled, Typography } from "@mui/material";
import { motion } from "framer-motion";
const MotionTypography = motion(Typography);
const MotionBox = motion(Box);
const MotionSpan = motion(styled('span')(({}) => ({

})));
const MotionImage = motion(styled('img')(({}) => ({

})))
export {
    MotionTypography,
    MotionBox,
    MotionSpan,
    MotionImage
}
