import { Box, Typography } from "@mui/material";
import { useScroll, useTransform } from "framer-motion";
import React, { useRef } from "react";
import { MotionBox } from "./MotionComponent";

const SickenCard = ({ sicken, progress, range, targetScale, index }) => {
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start end", "start start"],
  });
  const scale = useTransform(progress, range, [1, targetScale]);
  return (
    <Box
      ref={container}
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "sticky",
        top: "0px",
      }}
    >
      <MotionBox
        sx={{
          backgroundColor: "transparent",
          backdropFilter: "blur(30px)",
          boxShadow: "0 0 15px -5px rgba(0,0,0,.3)",
          display: "flex",
          position: "relative",
          flexDirection : index %2 !== 0 ? 'row-reverse' : 'row',
          top: -"25%",
          height: "500px",
          width: "100%",
          borderRadius: "25px",
          padding: "50px",
          transformOrigin: "top",
          gap: "10px",
          flexWrap: "wrap",
        }}
        style={{ scale, top: `calc(-5vh + ${index * 25}px)` }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "calc(50% - 10px)" },
            height: { xs: "200px", sm: "100%" },
            position: "relative",
          }}
        >
          <img
            src={sicken.image}
            alt={sicken.title}
            style={{
              objectFit: "cover",
              borderRadius: "20px",
              maxWidth : '100%'
            }}
          />
        </Box>
        <Box
          sx={{
            width: { xs: "100%", sm: "calc(50% - 10px)" },
            // height : '100%',
            display: "flex",
            // alignItems : 'center',
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              textTransform: "capitalize",
              fontWeight: "600px",
              fontFamily: "cursive",
              mb: 2,
            }}
          >
            {sicken.title}
          </Typography>
          <Typography
            sx={{
              lineHeight: "1.5",
            }}
            component={"em"}
          >
            {sicken.description}
          </Typography>
        </Box>
      </MotionBox>
    </Box>
  );
};

export default SickenCard;
