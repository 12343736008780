import { Box, Container } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  MotionBox,
  MotionImage,
  MotionSpan,
  MotionTypography,
} from "../components/MotionComponent";
import HeroImage from "../imgs/hero.jpg";

const Hero = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        position: "relative",
        marginBottom: '50px',
        overflow: 'hidden'
      }}
    >
      <MotionTypography
        variants={{
          hidden: {
            left: -200,
            opacity: 0,
          },
          show: {
            left: 0,
            opacity: 1,
            transition: {
              duration: 1,
            },
          },
        }}
        initial="hidden"
        whileInView={"show"}
        component={"q"}
        sx={{
          position: "absolute",
          fontSize: { xs: "100px", sm: "100px", md: "150px", lg: "200px" },
          fontFamily: "sans-serif",
          color: "text.heroGrey",
          top: "0",
          left: "0",
          zIndex: -1,
        }}
      >
        {t('hero.quote')}
      </MotionTypography>
      <Container
        fixed
        sx={{
          display: "flex",
          alignItems: "flex-end",
          minHeight: "100vh",
          pb: "100px",
          gap: "5px",
          flexWrap: "wrap",
        }}
      >
        <MotionBox
          sx={{
            width: { xs: "100%", sm: "calc(50% - 3px)" },
          }}
        >
          <MotionTypography
            variants={{
              hidden: {
                left: "-200px",
                opacity: 0,
              },
              show: {
                left: 0,
                opacity: 1,
                transition: {
                  delay: 1,
                  duration: 0.5,
                },
              },
            }}
            initial={"hidden"}
            whileInView={"show"}
            sx={{
              fontSize: "50px",
              fontWeight: "600",
              fontFamily: "cursive",
              lineHeight: "1.6",
              letterSpacing: "2px",
              position: "relative",
              "& .artista-word": {
                color: "doctor.lightGreen",
              },
            }}
          >
            <MotionSpan className="artista-word">
              {t('hero.heading').split(' ')[0]}
            </MotionSpan> {t('hero.heading').split(' ').slice(1).join(' ')}
          </MotionTypography>
        </MotionBox>
        <MotionBox
          initial="hidden"
          whileInView={"show"}
          animate="animating"
          sx={{
            width: { xs: "100%", sm: "calc(50% - 3px)" },
            textAlign: "center",
            position: "relative",
          }}
          variants={{
            hidden: {
              right: -200,
              opacity: 0,
            },
            show: {
              right: 0,
              opacity: 1,
              transition: {
                delay: 1.5,
                duration: 0.5,
              },
            },
            animating: {
              y: [-5, 5, -5],
              x: [-5, 5, -5],
              transition: {
                duration: 3,
                delay: 2.2,
                repeat: Infinity,
              },
            },
          }}
        >
          <MotionImage
            animate="animating"
            sx={{
              maxWidth: "400px",
            }}
            src={HeroImage}
          />
        </MotionBox>
      </Container>
    </Box>
  );
};

export default Hero;
