import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  MotionBox,
  MotionImage,
  MotionTypography,
} from "../components/MotionComponent";
import Image from "../imgs/teeth-2.png";

const Final = () => {
  const { t } = useTranslation();

  return (
    <Container sx={{ position: "relative" }}>
      <Box sx={{ display: "flex", alignItems: "stretch", flexWrap: "wrap" }}>
        <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
          <MotionImage
            variants={{
              hidden: { opacity: 0, right: -200 },
              show: { opacity: 1, right: 0, transition: { duration: 1 } },
            }}
            style={{ transformOrigin: "center center" }}
            initial="hidden"
            whileInView={"show"}
            src={Image}
            alt="teeth"
            sx={{ maxWidth: "100%", position: "relative" }}
          />
        </Box>
        <MotionBox
          initial={{ right: -200, opacity: 0 }}
          whileInView={{
            opacity: 1,
            right: 0,
            transition: { delay: 1.3, duration: 0.2 },
          }}
          sx={{ width: { xs: "100%", sm: "50%" }, position: "relative" }}
        >
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "500",
              lineHeight: "1.6",
              textTransform: "capitalize",
              fontFamily: "cursive",
            }}
          >
            {t("final.quote")}
          </Typography>
          <MotionBox>
            {t("final.paragraphs", { returnObjects: true }).map(
              (text, index) => (
                <MotionTypography
                  key={index}
                  initial={{ opacity: 0 }}
                  whileInView={{
                    opacity: 1,
                    transition: { delay: 1.6 + index * 0.1 },
                  }}
                  sx={{
                    fontSize : '20px'
                  }}
                  my={2}
                >
                  {text}
                </MotionTypography>
              )
            )}
          </MotionBox>
        </MotionBox>
      </Box>
    </Container>
  );
};

export default Final;
