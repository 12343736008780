import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  MotionBox,
  MotionImage,
  MotionTypography,
} from "../components/MotionComponent";
import HealthTeeth from "../imgs/health-teeth.jpg";
import decayTeeth from "../imgs/teeth-2.png";

const TouthConversation = () => {
  const { t } = useTranslation();

  return (
    <Container fixed sx={{ pb: "200px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "stretch",
          justifyContent: "space-between",
          gap: "10px",
          position: "sticky",
          top: "40vh",
        }}
      >
        <Box
          sx={{
            width: { xs: "50%", sm: "40%" },
            mb: 3,
          }}
        >
          <MotionImage
            src={HealthTeeth}
            variants={{
              hidden: {
                opacity: 0,
                left: -100,
              },
              show: {
                opacity: 1,
                left: 0,
              },
            }}
            initial={"hidden"}
            whileInView={"show"}
            viewport={{ margin: "0px 0px -300px 0px" }}
            sx={{
              maxWidth: "100%",
              position: "relative",
            }}
          />
          <MotionTypography
            variants={{
              hidden: {
                opacity: 0,
                top: -30,
              },
              show: {
                opacity: 1,
                top: 0,
              },
            }}
            initial={"hidden"}
            whileInView={"show"}
            viewport={{ margin: "0px 0px -50px 0px" }}
            sx={{
              textAlign: "center",
              fontWeight: "500",
              fontSize: "20px",
            }}
          >
            {t("teethConversation.healthyTooth")}
          </MotionTypography>
        </Box>
        <Box
          sx={{
            width: { xs: "50%", sm: "40%" },
          }}
        >
          <MotionImage
            src={decayTeeth}
            variants={{
              hidden: {
                opacity: 0,
                right: -100,
              },
              show: {
                opacity: 1,
                right: 0,
                transition: {
                  delay: 0.2,
                },
              },
            }}
            initial={"hidden"}
            whileInView={"show"}
            viewport={{ margin: "0px 0px -50px 0px" }}
            sx={{
              maxWidth: "100%",
              position: "relative",
            }}
          />
          <MotionTypography
            variants={{
              hidden: {
                opacity: 0,
                top: -30,
              },
              show: {
                opacity: 1,
                top: 0,
              },
            }}
            initial={"hidden"}
            whileInView={"show"}
            viewport={{ margin: "0px 0px -50px 0px" }}
            sx={{
              textAlign: "center",
              fontWeight: "500",
              fontSize: "20px",
            }}
          >
            {t("teethConversation.unhealthyTooth")}
          </MotionTypography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "30px",
          width: "100%",
        }}
      >
        {t("teethConversation.conversation", { returnObjects: true }).map(
          (con, i) => (
            <MotionBox
              key={i}
              variants={{
                hidden: {
                  opacity: 0,
                  top: -30,
                  scale: 0,
                },
                show: {
                  opacity: 1,
                  top: 0,
                  scale: 1,
                },
              }}
              initial={"hidden"}
              whileInView={"show"}
              viewport={{ margin: "0px 0px -300px 0px" }}
              style={{
                transformOrigin: i % 2 !== 0 ? "right" : "left",
              }}
              sx={{
                width: "70%",
                alignSelf: i % 2 !== 0 ? "flex-end" : "flex-start",
                backgroundColor: "text.heroGrey",
                textAlign: "center",
                p: 2,
                borderRadius: "10px",
                fontSize: "25px",
                zIndex: "3",
              }}
            >
              {con}
            </MotionBox>
          )
        )}
      </Box>
    </Container>
  );
}

export default TouthConversation