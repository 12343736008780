import { Box, Container } from "@mui/material";
import { useScroll } from "framer-motion";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import SickenCard from "../components/SickenCard";
import Image from "../imgs/teeth-2.png";

const TouthSick = () => {
  const { t } = useTranslation();
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start start", "end end"],
  });

  const sickens = t("toothSickness.items", { returnObjects: true });

  return (
    <Container>
      <Box
        ref={container}
        sx={{
          // position: "relative",
          // py: 10,
        }}
      >
        {sickens.map((sicken, i) => {
          const targetScale = 1 - (sickens.length - i) * 0.05;
          return (
            <SickenCard
              key={i}
              sicken={{ ...sicken, image: Image }}
              progress={scrollYProgress}
              range={[i * 0.25, 1]}
              targetScale={targetScale}
              index={i}
            />
          );
        })}
      </Box>
    </Container>
  );
};

export default TouthSick;
