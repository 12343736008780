import { Box, createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import TouthConversation from "./sections/TouthConversation";
import TouthSick from "./sections/TouthSick";
import Final from "./sections/Final";
import Hero from "./sections/Hero";
import "./App.css";
import "./i18n";
import Rtl from "./components/RTL/RTL";
import LanguageSelector from "./sections/LanguageSelector";
import { AnimatePresence } from "framer-motion";
import { Route, Routes } from "react-router";
import { MotionBox } from "./components/MotionComponent";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const pageVariants = {
  initial: {
    y : -200
  },
  animate: {
    y : 0,
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
  exit: {
    y: 200,
    transition: {
      duration: 0.5,
      ease: "easeInOut",
    },
  },
};

const Home = () => {
  return (
    <MotionBox
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <Hero />
      <TouthConversation />
      <TouthSick />
      <Final />
    </MotionBox>
  );
};

function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
    document.dir = i18n.language === "en" ? "ltr" : "rtl";
  }, [i18n.language]);
  const theme = createTheme({
    direction: i18n.language === "ar" ? "rtl" : "ltr",
    palette: {
      background: {
        white: "#fff",
      },
      text: {
        heroGrey: "#f0f0f0",
      },
      doctor: {
        lightGreen: "#6aa84f",
        darkGreen: "#274e13",
        mainGreen: "#38761d",
        lightTreeColor: "#9b6209",
        darkTreeColor: "#744700",
      },
      language: {
        light: "#D8A78B",
        dark: "#985E57",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AnimatePresence>
        <Rtl>
          <Routes>
            <Route path="/" element={<LanguageSelector />} />
            <Route path="/home" element={<Home />} />
          </Routes>
        </Rtl>
      </AnimatePresence>
    </ThemeProvider>
  );
}

export default App;
